.container {
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 1200px) {
    max-width: 1240px;
    display: block;
    margin: 0 auto;
  }
}

.containerHeaderMob {
  max-width: 360px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 30px 20px;
}

.containerMob {
  max-width: 360px;
  margin: 0px auto;
  width: 100%;
  padding: 30px 20px;
}

.containerFooter {
  max-width: 1280.5px;
  margin: 0px auto;
}
