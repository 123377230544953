.textWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin: 14px 0px 20px 0px;

  @media screen and (min-width: 1200px) {
    margin: 10px 0px 50px 0px;
    padding-top: 0px;
  }
}

.title {
  color: var(--color-black);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media screen and (min-width: 1200px) {
    font-size: 40px;
  }
}

.text {
  color: #717171;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 184px;

  @media screen and (min-width: 1200px) {
    font-size: 18px;
    width: auto;
  }
}
