@import "../../styles/vars.scss";
@import "../../styles/global";

.formHolder {
  height: 1022px;
  width: 320px;
  display: flex;
  flex-direction: column;
  padding: 5px 5px 0px 5px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.03);
  margin: 0px auto;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    justify-content: center;
    max-width: 1196px;
    width: 1196px;
    height: 667px;
    padding: 10px;
  }
}

.formHero {
  height: 397px;
  border-radius: 5px 5px 0px 5px;
  background: var(--color-black);
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  min-width: 310px;

  @media screen and (min-width: 1200px) {
    min-width: 491px;
    height: 647px;
    align-items: flex-start;
    padding: 40px 0 36px 40px;
    overflow: hidden;
  }
}

.formMain {
  @media screen and (min-width: 1200px) {
    display: flex;
    flex-direction: column;
    padding: 50px 40px 0px 50px;
  }
}

.formTitle {
  color: var(--color-white);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 15px;

  @media screen and (min-width: 1200px) {
    font-size: 28px;
    margin-top: 0px;
  }
}

.formText {
  color: var(--color-grey--variant);
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 6px;

  @media screen and (min-width: 1200px) {
    font-size: 18px;
  }
}

.formItemsHolder ul {
  margin-top: 12px;
  display: flex;
  gap: 15px;
  flex-direction: column;

  @media screen and (min-width: 1200px) {
    margin-top: 111px;
    gap: 50px;
  }
}

.formItemsHolder li {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  text-align: center;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    gap: 25px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  a {
    color: var(--color-white);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media screen and (min-width: 1200px) {
      font-size: 16px;
    }
  }
  a:last-child {
    width: 221px;
    @media screen and (min-width: 1200px) {
      text-align: start;
      width: 288px;
    }
  }
}

.formIconsHolder {
  margin-top: 58px;

  @media screen and (min-width: 1200px) {
    margin-top: 159px;
  }
}

.formCirclesHolder {
  position: absolute;
  width: 54px;
  height: 54px;
  bottom: 49px;
  right: 26px;
  z-index: 2;

  @media screen and (min-width: 1200px) {
    width: 138px;
    height: 138px;
    bottom: 71px;
    right: 70px;
  }
}

.formCirclesHolder svg {
  @media screen and (min-width: 1200px) {
    width: 138px;
    height: 138px;
  }
}

.formCirclesHolderSecond {
  position: absolute;
  width: 54px;
  height: 54px;
  top: 73.7%;
  right: 8.4%;

  @media screen and (min-width: 1200px) {
    width: 269px;
    height: 269px;
    top: 464px;
    left: 311px;
  }
}

.formCirclesHolderSecond svg {
  @media screen and (min-width: 1200px) {
    width: 269px;
    height: 269px;
    border-radius: 269px;
    transform: rotate(180deg);
  }
}

// SUBJECT AREA

.Subject {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0px 21px;
  margin-top: 5px;

  @media screen and (min-width: 1200px) {
    align-items: normal;
    padding: 0px;
    margin-top: 0px;
  }
}

.subjectTitle {
  color: var(--color-black);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.subjectRadioGroup {
  label {
    color: var(--color-black);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-basis: calc(50% - 0px);
    margin-bottom: 14px;

    @media screen and (min-width: 1200px) {
      flex-basis: auto;
    }

    input {
      margin-right: 10px;
    }
  }
}

.subjectRadioGroup {
  display: flex;
  flex-wrap: wrap;
  color: var(--color-black);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 10px 0px 11px 0px;
  width: 278px;

  @media screen and (min-width: 1200px) {
    flex-wrap: nowrap;
    width: auto;
    gap: 20px;
    margin: 14.26px 0px 31px 0px;
  }
}

.subjectRadioGroup input[type="radio"] {
  margin-right: 10px;
}

.subjectButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 278px;
  padding: 9.603px 30.729px;
  gap: 6.402px;
  margin: 25px 0px 5px 0px;
  color: var(--color-white);
  font-family: Poppins;
  text-align: center;
  font-size: 12.949px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  border-radius: 3.201px;
  background-color: var(--color-black);
  box-shadow: 0px 0px 8.96262px 0px rgba(0, 0, 0, 0.12);

  @media screen and (min-width: 1200px) {
    margin: 0px 0px 35px 0px;
    width: 214px;
    display: inline-flex;
    padding: 15px 48px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12);
    color: var(--color-white);

    text-align: center;
    font-size: 16px;
  }
}

.buttonHolder {
  @media screen and (min-width: 1200px) {
    display: flex;
    justify-content: flex-end;
  }
}

/* Hidden SVG */
input[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* Default SVG */
input[type="radio"] + label::before {
  content: "";
  display: inline-block;
  width: 13px;
  height: 13px;
  background: url("../../images/Radio.svg") center center no-repeat;
  background-size: contain;
  cursor: pointer;
  margin-right: 10px;
}

/* Checked SVG */
input[type="radio"]:checked + label::before {
  content: "";
  display: inline-block;
  width: 13px;
  height: 13px;
  background: url("../../images/RadioChecked.svg") center center no-repeat;
  background-size: contain;
  cursor: pointer;
  margin-right: 10px;
}

.sendImg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 104.404px;
  height: 52.149px;
  margin-left: -71px;

  @media screen and (min-width: 1200px) {
    width: 240.788px;
    height: 112.305px;
    margin-left: 270px;
  }
}

.formBox {
  @media screen and (min-width: 1200px) {
    display: flex;
  }
}

// .formContainer {
//   @media screen and (min-width: 1200px) {
//     display: flex;
//     flex-wrap: wrap;
//   }
// }
