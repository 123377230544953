:root {
  --color-white: #fff;
  --color-white--variant: #fbfbfb;
  --color-black: #000;
  --color-black--variant: #1f1f1f;
  --color-grey: #8d8d8d;
  --color-grey--variant: #c9c9c9;
  --color-grey--other: #717171;
}

// Transition with default value
@mixin Animation($properties...) {
  transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
