// SUBJECT AREA

.Subject {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0px 21px;
  margin-top: 5px;

  @media screen and (min-width: 1200px) {
    align-items: normal;
    padding: 0px;
    margin-top: 0px;
  }
}

.subjectTitle {
  color: var(--color-black);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.subjectRadioGroup {
  label {
    color: var(--color-black);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-basis: calc(50% - 0px);
    margin-bottom: 14px;

    @media screen and (min-width: 1200px) {
      flex-basis: auto;
    }

    input {
      margin-right: 10px;
    }
  }
}

.subjectRadioGroup {
  display: flex;
  flex-wrap: wrap;
  color: var(--color-black);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 10px 0px 11px 0px;
  width: 278px;

  @media screen and (min-width: 1200px) {
    flex-wrap: nowrap;
    width: auto;
    gap: 20px;
    margin: 14.26px 0px 31px 0px;
  }
}

.subjectRadioGroup input[type="radio"] {
  margin-right: 10px;
}

.subjectButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 278px;
  padding: 9.603px 30.729px;
  gap: 6.402px;
  margin: 25px 0px 5px 0px;
  color: var(--color-white);
  font-family: Poppins;
  text-align: center;
  font-size: 12.949px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  border-radius: 3.201px;
  background-color: var(--color-black);
  box-shadow: 0px 0px 8.96262px 0px rgba(0, 0, 0, 0.12);

  @media screen and (min-width: 1200px) {
    margin: 0px 0px 35px 0px;
    width: 214px;
    display: inline-flex;
    padding: 15px 48px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12);
    color: var(--color-white);

    text-align: center;
    font-size: 16px;
  }
}

/* Hidden SVG */
input[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* Default SVG */
input[type="radio"] + label::before {
  content: "";
  display: inline-block;
  width: 13px;
  height: 13px;
  background: url("../../images/Radio.svg") center center no-repeat;
  background-size: contain;
  cursor: pointer;
  margin-right: 10px;
}

/* Checked SVG */
input[type="radio"]:checked + label::before {
  content: "";
  display: inline-block;
  width: 13px;
  height: 13px;
  background: url("../../images/RadioChecked.svg") center center no-repeat;
  background-size: contain;
  cursor: pointer;
  margin-right: 10px;
}

.inputContainer {
  @media screen and (min-width: 1200px) {
    display: flex;
    flex-wrap: wrap;
  }
}

.inputContainer > .labelForMessage input {
  @media screen and (min-width: 1200px) {
    width: 595px;
  }
}

.buttonHolder {
  @media screen and (min-width: 1200px) {
    display: flex;
    justify-content: flex-end;
  }
}

.subjectButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 278px;
  padding: 9.603px 30.729px;
  gap: 6.402px;
  margin: 25px 0px 5px 0px;
  color: var(--color-white);
  font-family: Poppins;
  text-align: center;
  font-size: 12.949px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  border-radius: 3.201px;
  background-color: var(--color-black);
  box-shadow: 0px 0px 8.96262px 0px rgba(0, 0, 0, 0.12);

  @media screen and (min-width: 1200px) {
    margin: 0px 0px 35px 0px;
    width: 214px;
    display: inline-flex;
    padding: 15px 48px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.12);
    color: var(--color-white);

    text-align: center;
    font-size: 16px;
  }
}
