.inputField {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  height: 55px;

  @media screen and (min-width: 1200px) {
    width: calc(50% - 20px);
    margin-bottom: 45px;
    max-width: 278px;
  }
}

.inputField:first-child,
.inputField:nth-child(3) {
  @media screen and (min-width: 1200px) {
    margin-right: 39px;
  }
}

.label,
.labelBlack {
  color: var(--color-black);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

form .label:nth-child(2n),
.labelGray {
  color: var(--color-grey);
}

.input,
.inputFocused {
  width: 278px;
  border: none;
  border-bottom: 1px solid var(--color-grey);
  outline: none;
  font-weight: 500;
  font-size: 14px;
  transition: border-bottom-color 0.3s;
  color: var(--color-black);
  padding: 5px 0 10px 0;
  height: 35px;
}

.inputFocused {
  border-bottom-color: var(--color-black);
}

.input::placeholder {
  color: var(--color-grey);
}

.inputField:nth-child(odd) .label {
  color: var(--color-grey);
}

input {
  -webkit-appearance: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  -webkit-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
}
